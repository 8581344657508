import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useQueryParams } from 'use-query-params';

import { Card, CardBody, Pill, Value } from '@/components';
import { useTranslate } from '@/contexts';
import useSearchWorker from '@/hooks/useSearchWorker';
import { assert } from '@/utils/error';
import { SanityGameInfoBlockStaticQuery } from './__generated__/SanityGameInfoBlockStaticQuery';
import { queryParams } from './queryParams';

const BLOCK_STATIC_QUERY = graphql`
  query SanityGameInfoBlockStaticQuery {
    sanityGameInfoBlock {
      title {
        ...LocaleString
      }
    }
  }
`;

function pills(str: string) {
  if (!str) {
    return '-';
  }
  const elements = str
    .split(',')
    .map((item) => item.trim())
    .filter((item) => item !== '');

  return elements.map((e, i) => <Pill key={i}>{e}</Pill>);
}

const GameInfoBlock = () => {
  const { t } = useTranslate();
  const [query] = useQueryParams(queryParams);
  const params = { ...query, ...useParams() };

  assert(params.gameId, 'gameId missing');

  const block = useStaticQuery<SanityGameInfoBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityGameInfoBlock;

  const { rawGames, loading } = useSearchWorker();

  const game = loading
    ? null
    : // @ts-expect-error once rawGames is typed, this should not be needed
      rawGames.find(({ slug }) => slug === params.gameId);

  if (!block) {
    return null;
  }

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <Value fetching={loading} title={'Game Name'} value={game?.name} />
          <Value
            fetching={loading}
            title={'Game Provider'}
            value={game?.provider}
          />
          <Value
            fetching={loading}
            title={'Game Studio'}
            value={game?.studio}
          />
          <Value
            fetching={loading}
            title={'Release Date'}
            value={game?.releaseDate}
          />
          <Value fetching={loading} title={'RTP'} value={game?.rtp} />
          <Value fetching={loading} title={'Category'} value={game?.category} />

          <Value fetching={loading} title={'Lines'} value={game?.lines} />
          <Value fetching={loading} title={'Layout'} value={game?.layout} />
          <Value fetching={loading} title={'Features'}>
            <div className="space-x-1">{pills(game?.features)}</div>
          </Value>
          <Value fetching={loading} title={'Theme'}>
            <div className="space-x-1">{pills(game?.theme)}</div>
          </Value>
        </div>
      </CardBody>
    </Card>
  );
};

export default GameInfoBlock;
